import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import CareersHowWeHire from '../careers-how-we-hire';
import { linkify } from '../../../utils/functions';
import './style.scss';
import Link from '../../link';

/**
 * Jobs Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */

const LOCATIONS = {
	TEL_AVIV: 'Tel Aviv',
	NY: 'New York'
}

const FILTER_PARAMS = {
	LOCATION: 'location',
	DEPARTMENT: 'department',
	SEARCH: 'search',
	RESET: 'reset'
}

const Jobs = ({ jobs }) => {
	const {
		allJobs
	} = jobs;

	let allDepartments = [];
	const locations = [];


	const departments = [];

	allJobs.edges.forEach((departmentEdge) => {
		const department = departmentEdge.node.name;

		if (departments.indexOf(departments) === -1) {
			departments.push(department);
		}
		const allJeys = department in allDepartments;

		if (!allJeys) {
			allDepartments[department] = [];
		}

		let allDepartmentsJobs = allJobs.edges.find((edge) => edge.node.name === department) || {};
		let allPositions = get(allDepartmentsJobs, 'node[jobs]') || [];

		// console.log(allPositions);

		if (!isEmpty(allPositions)) {
			allPositions.forEach(item => {
				item.department = department;
				if (locations.indexOf(item.location.name) === -1) {
					locations.push(item.location.name);
				}
			});
			allDepartments = allDepartments.concat(allPositions);
		}
	});




	/* Filter handlers */

	const blankFilter = {
		[FILTER_PARAMS.DEPARTMENT]: false,
		[FILTER_PARAMS.SEARCH]: '',
		[FILTER_PARAMS.LOCATION]: false,
	};

	const [filter, setFilter] = useState({ ...blankFilter });



	const handleFilter = (name, value) => {
		let newObj = {};

		if (name === FILTER_PARAMS.RESET) {
			newObj = { ...blankFilter };

		} else {
			newObj = {
				...filter,
				[name]: value
			};
		}

		setFilter({ ...newObj })
	}


	const handleDepartmentFilter = (item) => filter.department && filter.department !== `false` ? item.department === filter.department : item;
	const handleLocationFilter = (item) => filter.location && filter.location !== `false` ? item.location.name === filter.location : item;
	const handSearchFilter = (item) => filter.search ? item.title.toLowerCase().includes(filter.search.toLowerCase()) : item;



	return (
		<>
			<div className="row" id="join">
				<div className="column medium-1 small-0"></div>
				<div className="column large-10 medium-12 small-12">
					<div className="ow-jobs-form">
						<h2 className="join-our-tem-title reset-title-styles ow-h2-title">Join Our Team</h2>
						<div className="ow-jobs-form-header">
							<div className="ow-jobs-filters">
								{/* eslint-disable-next-line */}
								<select value={filter.location} className="ow-jobs-filter" onChange={(e) => handleFilter(FILTER_PARAMS.LOCATION, e.target.value)}>
									<option value={false}>All Locations</option>
									{locations.map((location, index) => <option key={index} value={location}>{location}</option>)}
								</select>
								{/* eslint-disable-next-line */}
								<select value={filter.department} className="ow-jobs-filter" onChange={(e) => handleFilter(FILTER_PARAMS.DEPARTMENT, e.target.value)}>
									<option value={false}>All Departments</option>
									{departments.map((item, key) => <option key={key} value={item}>{item}</option>)}
								</select>
							</div>
							<div className="ow-jobs-form-search-input">
								<input value={filter.search} onChange={(e) => handleFilter(FILTER_PARAMS.SEARCH, e.target.value)} className="ow-jobs-filter search-input" placeholder="Search for a role" />
								<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="7.63636" cy="7.63636" r="7.13636" stroke="black" />
									<path d="M12.8184 12.8184L20.8184 20.8184" stroke="black" />
								</svg>

							</div>
						</div>
						<ul className="ow-jobs-list">
							{allDepartments.filter(handleDepartmentFilter).filter(handleLocationFilter).filter(handSearchFilter).map((job, index) => (
								<li key={`job-${index}`} className="ow-job-item">
									<Link url={`/careers/jobs/${linkify(job.location.name)}/${linkify(job.title)}`}><h5 className="ow-job-title">{job.title}</h5><span className="ow-job-location">{job.location.name}</span></Link>
								</li>
							))}
							{
								allDepartments.filter(handleDepartmentFilter).filter(handleLocationFilter).filter(handSearchFilter).length === 0 && <h5 className="no-positions-message">No open positions matching the selected filters.<br />Try anything else, or come back later.</h5>
							}
						</ul>
						<button className={filter.department || filter.location || filter.search.length > 0 ? "ow-jobs-reset-filter " : "ow-jobs-reset-filter inactive"} onClick={() => handleFilter(FILTER_PARAMS.RESET)}>Reset filters</button>
					</div>
				</div>
			</div>
			<CareersHowWeHire />
		</>
	);
};

Jobs.propTypes = {
	jobs: PropTypes.object,
	location: PropTypes.string
};

Jobs.defaultProps = {
	jobs: {},
}

export default Jobs;