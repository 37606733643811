import React from 'react';
import './style.scss';

const CareersHowWeHire = () => {

	// const { title, excerpt } = data;

	return (
		<section className="careers-sections how-we-hire-block">
			<div className='how-we-hire-inner'>
				<div className="row">
					<div className="medium-1 small-12 column" />
					<div className="medium-4 small-12 column">
						<img src="https://www.openweb.com/uploads/2022/02/how-we-hire-dark.svg" alt="How We Hire" />
					</div>
					<div className="medium-1 small-12 column" />
					<div className="medium-5 small-12 column how-we-hire-right">
						<h2>How We Hire</h2>
						<p>Learn more about how we hire at OpenWeb, and how our process is designed to set you up for success.</p>
						<a href="/how-we-hire/" className='hiring-process-button'>Hiring process</a>
					</div>
					<div className="medium-1 small-12 column" />
				</div>
			</div>
		</section>
	);

};

export default CareersHowWeHire;
